/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  colors,
  Typography,
  Popover,
  Divider
} from '@material-ui/core';
import axios from 'utils/axios';
import Avatar from '@material-ui/core/Avatar';
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import useRouter from 'utils/useRouter';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import moment from 'moment';

import Api from '../../../../Helper/ApiHandler';
var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
  },
  flexGrow: {
    flexGrow: 1
  },
  userName: {
    marginRight: "8px",
    fontWeight: 500,
    textTransform: "capitalize"
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  avtar: {
    width: "50px",
    height: "50px",
    marginRight: "20px",
    cursor: "pointer",
    textTransform: "capitalize"
  },
  menu: {
    padding: "10px"
  },
  linkMenu: {
    textDecoration: "none",
    color: "black",
  },
  iconMessage: {
    color: "#0071CE",
    fontSize: "26px",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
    },
    cursor: "pointer"
  },
  notificationCount: {
    [theme.breakpoints.down('xs')]: {
      top: "15px",
      right: "-14px",
    },
    background: "red",
    color: "white",
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    right: "-37px",
    top: "-2px",
    fontSize: "11px"
  },
  seeAllNotification: {
    textAlign: "center",
    width: "100%",
    //fontFamily: "Gilroy !important",
    margin: "0",
    fontSize: "14px",
    marginLeft: "10px",
    color: "#0071CE",
    textDecoration: "underline",
    fontWeight: "600",
    cursor: "pointer"
  },
  notificationDetail: {
    margin: "0",
    fontSize: "12px",
    whiteSpace: "normal",
    width: "100%",
    fontWeight: "900",
    padding: "0px 0px 0px 10px"
  },
  nameHeadingNotification: {
    //fontFamily: "Gilroy !important",
    margin: "0",
    fontSize: "14px",
    fontWeight: "600"
  },
  imageNotification: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    objectFit: "cover"
  },
  imageNotificationRoot: {
    minWidth: 0
  },
  name: {
    fontWeight: "600"
  },
  timeMessage: {
    fontWeight: "400",
    fontSize: "12px"
  },
  unreadNotification: {
    fontSize: "10px",
    color: "white",
    fontWeight: "400"
  },
  expireNajik: {
    backgroundColor: "red",
    borderRadius: "10px",
    color: "white",
    padding: "10px",
    fontWeight: "400"
  },
  expire: {
    backgroundColor: "#10a64a",
    borderRadius: "10px",
    color: "white",
    padding: "10px",
    fontWeight: "400"
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;
  const router = useRouter();

  const classes = useStyles();
  const [userName, setUserName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notification, setNotification] = React.useState(null);
  const [unreadNotification, setUnreadNotification] = React.useState(0);
  const [lstNotification, setLstNotification] = React.useState([]);
  const [objSubscription, setObjSubscription] = React.useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    onLoad()
    let mounted = true;
    const fetchNotifications = async () => {
      let name = await AsyncLocalStorage.getItem("userName")
      let url = await AsyncLocalStorage.getItem("userProfile")
      setUserName(name)
      setImageUrl(url)
      axios.get('/api/account/notifications').then(response => {
        if (mounted) {
          // setNotifications(response.data.notifications);
        }
      });
    };

    fetchNotifications();

    return () => {
      mounted = false;
    };
  }, []);

  const onLoad = async () => {
    api.get(`propertyinfo/getcurrentownersubscr`)
      .then((result) => {
        if (result.success === "true") {
          setObjSubscription(result.data)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });

    api.get(`propertyinfo/owner/getnotificationlist?page=${0}&size=${5}`)
      .then((result) => {
        if (result.success === "true") {
          setLstNotification(result.data.property)
          // setUnreadNotification()
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });

    api.get(`propertyinfo/owner/unreadnotification`)
      .then((result) => {
        if (result.success === "true") {
          setUnreadNotification(result.data)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  };

  function onSeeAll() {
    // Api Call For Read notification
    api.get(`propertyinfo/owner/readallnotification`)
      .then((result) => {
        if (result.success === "true") {
          handleCloseNotification()
          router.history.push("/manageRquests")
          setUnreadNotification(0)
        } else {
          console.log(result, "error")
        }
      })
      .catch((err) => {
        console.log(err, "catch error")
      });
  }

  const handleClickNotification = (event) => {
    setNotification(event.currentTarget);
  };

  const removeToken = () => {
    // removeItem
    AsyncLocalStorage.removeItem("authToken")
    router.history.push('/property/login');
  }
  const handleCloseNotification = () => {
    setNotification(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={"/images/logos/logo--white.svg"}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <div style={{ color: "black" }}>
          {moment(objSubscription.validity_expire_date).diff(moment(), 'days') < 0 ?
            <Typography
              component="h6"
              variant="h6"
              className={moment(objSubscription.validity_expire_date).diff(moment(), 'days') <= 7 ? classes.expireNajik : classes.expire}
            >
              Your plan expired on {moment(objSubscription.validity_expire_date).format("DD/MM/YYYY")}
            </Typography>
            :
            <Typography
              component="h6"
              variant="h6"
              className={moment(objSubscription.validity_expire_date).diff(moment(), 'days') <= 7 ? classes.expireNajik : classes.expire}
            >
              Your plan will be expired on {moment(objSubscription.validity_expire_date).format("DD/MM/YYYY")}
            </Typography>
          }
        </div>
        <div style={{ position: "relative", bottom: "6px" }}>
          <div className={classes.notificationCount}>
            <Typography
              component="h6"
              variant="h6"
              className={classes.unreadNotification}
            >
              {unreadNotification}
            </Typography>
          </div>
        </div>
        <Tooltip title="Notification" placement="top">
          <NotificationsNoneIcon className={classes.iconMessage} onClick={handleClickNotification} />
        </Tooltip>&nbsp;&nbsp;

        <Popover
          id={id}
          open={Boolean(notification)}
          anchorEl={notification}
          onClose={handleCloseNotification}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.menu}
        >
          <div>
            {lstNotification && lstNotification.length ?
              lstNotification.map((item, index) => (
                <>
                  <Divider />
                  <div style={{ marginRight: "5px", display: "flex", alignItems: "center", padding: "5px" }}
                  // onClick={() => {
                  //   onProfile(item.slug)
                  // }}
                  >
                    <ListItemIcon className={classes.imageNotificationRoot}>
                      <img
                        className={classes.imageNotification}
                        src={item.profile_picture ? item.profile_picture : "/images/male_defualt.svg"}
                      />
                    </ListItemIcon>
                    {/* <ListItemText primary={item.message} /> */}
                    <p className={classes.notificationDetail}>
                      <Typography
                        component="h6"
                        variant="h6"
                        className={classes.userName}
                      >
                        {/* {userName} */}
                        <span className={classes.name}> {item.name} </span>
                        <span className={classes.timeMessage}> {item.message} </span>
                        {/* <div className={classes.timeNotifiction}> {moment(new Date(item.createdAt)).fromNow()}</div> */}
                        <div className={classes.timeNotifiction}>
                          {/* {timeAgo(new Date(item.createdAt))} */}
                        </div>
                      </Typography>
                    </p>
                  </div>
                </>
              )) :
              <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "200px", alignItems: "center", padding: "10px" }}>
                <Typography
                  variant="caption"
                  component="primary"
                  color="primary"
                  style={{ fontSize: "12px", color: "gray", fontWeight: "400" }}
                >
                  You have no notifications
                </Typography>
              </div>
            }
            {lstNotification && lstNotification.length ?
              <Divider />
              :
              ""
            }
            {lstNotification && lstNotification.length ?
              <div>
                <Typography
                  component="h6"
                  variant="h6"
                  style={{ padding: "5px" }}
                >
                  <p
                    className={classes.seeAllNotification}
                    onClick={() => {
                      onSeeAll()
                    }}
                  >
                    See All
                  </p>
                </Typography>
              </div>
              :
              ""
            }
          </div>
        </Popover>

        <Typography
          component="h6"
          variant="h6"
          className={classes.userName}
        >
          {userName}
        </Typography>
        <Tooltip title="Profile" placement="top">
          <Avatar alt={userName} src={imageUrl} className={classes.avtar} onClick={handleClick} />
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.menu}
        >
          <div>
            <div style={{ padding: "10px", display: "flex" }} onClick={() => { handleClose() }}>
              <PermIdentityIcon color="primary" />&nbsp;&nbsp;&nbsp;
              <Link className={classes.linkMenu} to="/your-profile">
                <Typography
                  variant="h6"
                  component="h5"
                  style={{ fontSize: "14px", fontWeight: "400" }}
                >
                  My profile
                </Typography>
              </Link>
            </div>
            <Divider />
            <div style={{ padding: "10px", display: "flex" }} onClick={() => { handleClose() }}>
              <img src="/images/changePassword.svg" />&nbsp;&nbsp;&nbsp;
              <Link className={classes.linkMenu} to="/changepassword">
                <Typography
                  variant="h6"
                  component="h5"
                  style={{ fontSize: "14px", fontWeight: "400" }}
                >
                  Change Password
                </Typography>
              </Link>
            </div>
            <Divider />

            <div style={{ padding: "10px", display: "flex" }} onClick={() => { removeToken() }}>
              <img src="/images/logout.svg" />&nbsp;&nbsp;&nbsp;
              <Link className={classes.linkMenu} to="/your-profile">
                <Typography
                  variant="h6"
                  component="h5"
                  style={{ fontSize: "14px", fontWeight: "400" }}
                >
                  Logout
                </Typography>
              </Link>
            </div>
          </div>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
