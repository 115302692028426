import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DvrIcon from '@material-ui/icons/Dvr';
import HomeIcon from '@material-ui/icons/Home';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import ChatIcon from '@material-ui/icons/Chat';

export default [
  {
    title: 'MANAGEMENT',
    pages: [
      {
        title: 'Manage Organizations',
        href: '/manageOrganization',
        icon: PersonIcon
      },
      {
        title: 'Manage Users',
        href: '/manageUser',
        icon: PeopleIcon
      },
      {
        title: 'Promocodes',
        href: '/promocodes',
        icon: ReceiptIcon
      },
      {
        title: 'Subscription Plans',
        href: '/subscriptionPlans',
        icon: DvrIcon
      },
      {
        title: 'Payment Subscriptions',
        href: '/paymentSubscription',
        icon: LocalAtmIcon
      },
      {
        title: 'Manage Report',
        href: '/manageReport',
        icon: ReportOutlinedIcon
      },
      {
        title: 'Property',
        href: '/property',
        icon: HomeIcon
      },
      {
        title: 'Blog',
        href: '/manage/blog',
        icon: ChatIcon
      },
      {
        title: 'Manage',
        href: '/manage',
        icon: SettingsIcon,
        children: [
          {
            title: 'CMS',
            href: '/manage/cms'
          },
          {
            title: 'Testimonials',
            href: '/manage/testimonials'
          },
          {
            title: 'University',
            href: '/manage/university'
          }
        ]
      },

    ]
  }
];
