/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  colors,
  Typography
} from '@material-ui/core';
import axios from 'utils/axios';
import Avatar from '@material-ui/core/Avatar';
import AsyncLocalStorage from '@createnextapp/async-local-storage'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
  },
  flexGrow: {
    flexGrow: 1
  },
  userName: {
    marginRight: "8px",
    fontWeight: 500
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  avtar: {
    width: "50px",
    height: "50px",
    marginRight: "20px"
  }
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  // const { history } = useRouter();
  // const searchRef = useRef(null);
  // const dispatch = useDispatch();
  // const notificationsRef = useRef(null);
  // const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  // const [searchValue, setSearchValue] = useState('');
  // const [notifications, setNotifications] = useState([]);
  // const [openNotifications, setOpenNotifications] = useState(false);
  // capitalize string
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    let mounted = true;

    const fetchNotifications = async () => {
      let name = await AsyncLocalStorage.getItem("userName")
      let url = await AsyncLocalStorage.getItem("userProfile")
      setUserName(name)
      setImageUrl(url)
      axios.get('/api/account/notifications').then(response => {
        if (mounted) {
          // setNotifications(response.data.notifications);
        }
      });
    };

    fetchNotifications();
    return () => {
      mounted = false;
    };
  }, []);

  // const handleLogout = () => {
  //   history.push('/auth/login');
  //   // dispatch(logout());
  // };

  // const handlePricingOpen = () => {
  //   setPricingModalOpen(true);
  // };

  // const handlePricingClose = () => {
  //   setPricingModalOpen(false);
  // };

  // const handleNotificationsOpen = () => {
  //   setOpenNotifications(true);
  // };

  // const handleNotificationsClose = () => {
  //   setOpenNotifications(false);
  // };

  // const handleSearchChange = event => {
  //   setSearchValue(event.target.value);

  //   if (event.target.value) {
  //     if (!openSearchPopover) {
  //       setOpenSearchPopover(true);
  //     }
  //   } else {
  //     setOpenSearchPopover(false);
  //   }
  // };

  // const handleSearchPopverClose = () => {
  //   setOpenSearchPopover(false);
  // };

  // const popularSearches = [
  //   'Devias React Dashboard',
  //   'Devias',
  //   'Admin Pannel',
  //   'Project',
  //   'Pages'
  // ];
  console.log(imageUrl, "imageUrl")
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    // color="primary"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={"/images/logos/logo--white.svg"}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Typography
          component="h6"
          variant="h6"
          className={classes.userName}
        >
          {capitalizeFirstLetter(userName)}
          {/* John Smith */}
          {/* {AsyncLocalStorage.getItem("userName")} */}
        </Typography>

        {/* <Avatar alt={userName} src={imageUrl === null ? "/images/avatars/man_avtar.png" : imageUrl} className={classes.avtar} /> */}
        
        <Avatar alt={userName} src={'/images/avatars/man_avtar.png'} className={classes.avtar} />
        {/* <Hidden smDown> */}
        {/* <div
            className={classes.search}
            ref={searchRef}
          >
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search people &amp; places"
              value={searchValue}
            />
          </div> */}
        {/* <Popper
            anchorEl={searchRef.current}
            className={classes.searchPopper}
            open={openSearchPopover}
            transition
          >
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper
                className={classes.searchPopperContent}
                elevation={3}
              >
                <List>
                  {popularSearches.map(search => (
                    <ListItem
                      button
                      key={search}
                      onClick={handleSearchPopverClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper> */}
        {/* <Button
            className={classes.trialButton}
            onClick={handlePricingOpen}
            variant="contained"
          >
            <LockIcon className={classes.trialIcon} />
            Trial expired
          </Button>
        </Hidden> */}
        {/* <Hidden mdDown>
          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden> */}
        {/* <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
      {/* <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      /> */}
      {/* <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      /> */}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
